var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Thêm brand cho trường",
        visible: _vm.dialogVisible,
        width: "45%",
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "editGrade",
          attrs: {
            "label-width": "140px",
            model: _vm.editGrade,
            "label-position": "left",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Tên trường", prop: "schoolName" } },
            [_vm._v(_vm._s(_vm.editGrade.schoolName))]
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Chọn brand", prop: "idBrand" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "select-grade",
                  staticStyle: { width: "100%" },
                  attrs: { clearable: "", placeholder: "Chọn brand" },
                  model: {
                    value: _vm.editGrade.brand,
                    callback: function ($$v) {
                      _vm.$set(_vm.editGrade, "brand", $$v)
                    },
                    expression: "editGrade.brand",
                  },
                },
                _vm._l(_vm.brandnamelist, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { value: item.id, label: item.brandName },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.closeDialogByButton("editGrade")
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-close" }),
              _c("span", [_vm._v("Đóng")]),
            ]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "success",
                size: "medium",
                loading: _vm.loadingButton,
                mini: "",
              },
              on: {
                click: function ($event) {
                  return _vm.submitForm("editGrade")
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-check" }),
              _c("span", [_vm._v("Lưu")]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }